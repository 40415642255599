<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button size="big" type="primary" @click="submitForm">
        Сохранить
      </el-button>
    </div>
    <el-form
      ref="form"
      :rules="rules"
      :class="$style.form"
      :model="settings"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Дата" prop="date">
        <el-date-picker
          v-model="settings.date"
          type="month"
          placeholder="Pick a month"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Заголовок (max=14)" prop="title">
        <el-input v-model="settings.title" maxlength="14"></el-input>
      </el-form-item>
      <el-form-item label="Подзаголовок (max=27)" prop="subtitle">
        <el-input v-model="settings.subtitle" maxlength="27"></el-input>
      </el-form-item>
      <el-form-item label="Описание (max=182)" prop="description">
        <el-input
          v-model="settings.description"
          :rows="2"
          type="textarea"
          maxlength="182"
          placeholder="Please input"
        />
      </el-form-item>
      <el-form-item label="Номер телефона для связи" prop="phone">
        <el-input v-model="settings.phone"></el-input>
      </el-form-item>
      <el-form-item
        label="Номер телефона для связи через telegram"
        prop="tgPhone"
      >
        <el-input v-model="settings.tgPhone"></el-input>
      </el-form-item>
      <el-form-item label="Почта" prop="mail">
        <el-input v-model="settings.mail"></el-input>
      </el-form-item>
      <el-form-item label="Адрес" prop="address">
        <el-input v-model="settings.address"></el-input>
      </el-form-item>
      <el-form-item label="Рабочие дни/часы" prop="workingHours">
        <el-input v-model="settings.workingHours"></el-input>
      </el-form-item>
      <el-form-item label="Отображать цену" prop="priceDisplay">
        <el-select
          v-model="settings.priceDisplay"
          placeholder="Выберите отображаемые цены"
          size="large"
        >
          <el-option
            v-for="item in priceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Скидка %" prop="discountPercent">
        <el-input
          type="number"
          min="0"
          max="100"
          v-model="settings.discountPercent"
        ></el-input>
      </el-form-item>
      <el-form-item label="Цвет логотипа">
        <el-color-picker v-model="settings.logoColor" />
      </el-form-item>
      <el-form-item label="Цвет текста под логотипом">
        <el-color-picker v-model="settings.logoTextColor" />
      </el-form-item>
      <el-form-item label="Главная страница фото" prop="mainImages">
        <Uploader
          :limit="1"
          :files="settings?.mainImages ?? []"
          @upload="uploadImages('mainImages', $event)"
        />
      </el-form-item>

      <el-form-item label="Контакты фото" prop="contactsImages">
        <Uploader
          :limit="1"
          :files="settings?.contactsImages ?? []"
          @upload="uploadImages('contactsImages', $event)"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

import Uploader from '@/components/moleculs/Uploader.vue'

export default {
  components: { Uploader },
  mixins: [notifications],
  data() {
    return {
      priceOptions: [
        {
          value: 'PRICE_WITHOUT_DISCOUNT',
          label: 'Стоимость без скидки ',
        },
        {
          value: 'PRICE_AND_DISCOUNT',
          label: 'Стоимость и цена со скидкой ',
        },
        {
          value: 'DISCOUNT_WITHOUT_PRICE',
          label: 'Без стоимости но с ценой со скидкой ',
        },
        {
          value: 'WITHOUT_DISCOUNT_AND_PRICE',
          label: 'Без стоимости и без цены со скидкой ',
        },
      ],
      settings: {
        date: '',
        title: '',
        subtitle: '',
        description: '',
        mainImages: [],
        contactsImages: [],
        phone: '',
        tgPhone: '',
        mail: '',
        address: '',
        workingHours: '',
        priceDisplay: '',
        discountPercent: 0,
        logoColor: '',
        logoTextColor: '',
      },
      rules: {
        date: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        title: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        subtitle: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        tgPhone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        mail: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.email,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        address: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        workingHours: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        mainImages: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        contactsImages: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        discountPercent: [
          {
            required: true,
            trigger: 'change',
            validator: this.validateDiscount,
          },
        ],
        priceDisplay: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
      },
    }
  },

  async created() {
    await this.getSettings()
  },
  methods: {
    validateDiscount(_, value, callback) {
      if (!value) callback(new Error('Пожалуйста, заполните поле'))
      value <= 100
        ? callback()
        : callback(new Error('Скидка не может быть больше 100%'))
    },

    uploadImages(path, images) {
      this.settings[path] = images
    },
    async getSettings() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PdfCatalogActions.getTemplate()

      loading.close()

      if (error) {
        error !== 'Error: mongo: no documents in result' &&
          this.showNotification('Ошибка получения настроек', 'error')
        return
      }

      this.settings = {
        ...value,
        mainImages: [
          {
            id: value.contactsImage,
            order: 1,
            original: value.mainImage,
          },
        ],
        contactsImages: [
          {
            id: value.contactsImage,
            order: 1,
            original: value.contactsImage,
          },
        ],
      }
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.ContentServiceCore.PdfCatalogActions.updateTemplate({
            ...this.settings,
            discountPercent: Number(this.settings.discountPercent),
            mainImage: this.settings.mainImages?.[0]?.original,
            contactsImage: this.settings.contactsImages?.[0]?.original,
          })

        loading.close()

        if (error) {
          this.showNotification('Ошибка сохранения настроек', 'error')
          return
        }

        this.showNotification('Настройки успешно сохранены', 'success')
        this.$router.push(ADDWINE_ADMIN_ROUTES.INDEX)
      })
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem 2rem 1rem 1rem;
  .wrapper {
    @include stickyWrapper;
  }

  .form {
    & > div > label {
      text-align: left;
    }
  }
}
</style>
